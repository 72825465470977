@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

$background: #F1F1F1;
$light-gray: #EAEAEA;
$gray: #CCCCCC;
$theme: #971BEA;
$theme-dark: #2E0838;

$shadow-light: #00000029;
$shadow-dark: #00000080;

$text-white: #FFFFFF;
$text-black: #08010C;

$border-radius: 1rem;

$page-padding: 1rem;

$header-height: 1rem;
$header-padding: 1.5rem;

$block-padding: 4rem;

$max-width: 45rem;

$rect-padding: 2rem;
$rect-background: $gray;
$rect-drop-shadow: 0px 16px 36px $shadow-light;

$homepage-element-max-width: 20rem;

// Bootstrap overrides
$body-bg: $background;
$body-color: $text-black;

$colors: (
  "background": $background,
  "gray": $gray,
  "theme": $theme,
  "theme-dark": $theme-dark,
);

$theme-colors: (
  "primary":    $theme,
);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");

$celdum: Celdum-Regular, system-ui, -apple-system, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$celdum-bold: Celdum-Bold, system-ui, -apple-system, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$norpeth: Norpeth-Medium, system-ui, -apple-system, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$norpeth-bold: Norpeth-DemiBold, system-ui, -apple-system, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$font-family-sans-serif: $norpeth;
$headings-font-family: $celdum-bold;
$btn-font-family: $celdum-bold;
