h1, h2, h3, h4, h5, h6 {
  text-align: center;
  text-transform: uppercase;
}

p {
  max-width: $max-width;
  @include margin-x(auto);
}

.celdum {
  font-family: $celdum;
}

.celdum-bold {
  font-family: $celdum-bold;
}

.norpeth {
  font-family: $norpeth;
}

.norpeth-bold {
  font-family: $norpeth-bold;
}
