$privacy-margin: 2.5rem;

#welcome.privacy-statement {
  #welcome-background-1 {
    bottom: -1 * $privacy-margin
  }

  #privacy-statement {
    margin-top: $privacy-margin;
  }
}
