$background-color: #D9D8D9;
$apps-padding: $block-padding * 0.25;

#apps {
  background-color: $background-color;
  @include padding-y($block-padding);
  text-align: center;

  h1 {
    font-size: medium;
  }

  h2 {
    font-size: larger;
    color: $theme;
  }

  #apps-download-buttons {
    display: flex;
    justify-items: center;
    padding-top: $apps-padding;

    a {
      display: block;
      flex: 1 1 auto;
      height: 4rem;
      padding-left: $apps-padding;
      padding-right: $apps-padding;
    }

    .app {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: contain;
      background-origin: content-box;

      &.ios {
        background-position: right;
      }

      &.android {
        background-position: center;
      }

      &.amazon {
        background-position: left;
      }
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;

      a {
        margin-bottom: $apps-padding;
      }

      .app {
        &.ios, &.amazon {
          background-position: center;
        }
      }
    }
  }

  .amazon {
    background-image: url("../../img/app/amazon_en.svg");
  }

  .en & {
    .android {
      background-image: url("../../img/app/android_en.svg");
    }

    .ios {
      background-image: url("../../img/app/ios_en.svg");
    }
  }

  .nl & {
    .android {
      background-image: url("../../img/app/android_nl.svg");
    }

    .ios {
      background-image: url("../../img/app/ios_nl.svg");
    }
  }
}
