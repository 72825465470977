$faq-color: darken($theme, 20%);
$faq-color-2: darken($theme-dark, 5%);

$faq-padding: 1rem;
$dropdown-button-size: 2.5rem;

#faq {
  background: $faq-color;
  background: linear-gradient(90deg, $faq-color 0%, $faq-color-2 100%);
  color: $white;

  #faq-container {
    @include padding-y($block-padding);
    @include margin-x(auto);
    @include media-breakpoint-up(md) {
      max-width: 50rem;
    }

    .faq-element {
      display: flex;
      @include padding-y($faq-padding);

      border-bottom: 2px solid $white;

      .faq-text {
        flex: 1 1 auto;

        .faq-question {
          text-transform: uppercase;
          text-align: left;
          @include margin-y(0);
        }

        .faq-answer > div > * {
          max-width: 100%;
          @include margin-y(0);
        }
      }

      .faq-dropdown {
        flex: 0 0 $dropdown-button-size;

        > div {
          display: none;
          width: $dropdown-button-size;
          height: $dropdown-button-size;
          border-radius: $dropdown-button-size;
          background-color: $theme;
          text-align: center;
          background-image: url("../../img/dropdown.svg");
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }

  #end-logo {
    height: 8rem;
    background-image: url("../../img/logo/outline-white.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    @include media-breakpoint-down(md) {
      background-image: url("../../img/logo/normal.svg");
    }
  }

  #end-spacer {
    @include media-breakpoint-up(md) {
      height: 5vh;
    }
  }
}
