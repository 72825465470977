$btn-border-radius: 10rem;
$btn-font-size: 1rem;
$btn-padding-x: 2rem;
$btn-padding-y: 0.75rem;

@import "~bootstrap/scss/_buttons";

.btn {
  text-transform: uppercase;
}
