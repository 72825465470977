$feature-padding-y: 1rem;
$feature-padding-x: 4rem;

#features {
  padding-bottom: $block-padding;

  @include media-breakpoint-down(md) {
    padding: 0;
  }

  .feature {
    border-radius: $border-radius;
    padding: $feature-padding-y $feature-padding-x;
    max-width: $max-width;
    margin: auto;

    @include media-breakpoint-down(md) {
      border-radius: 0;
      @include padding-y($feature-padding-y * 2);
    }

    &:nth-child(even) {
      background-color: $light-gray;
    }

    h6 {
      margin-bottom: 0;
    }

    .feature-image {
      width: 5rem;
      aspect-ratio: 1;

      background-image: url("../../img/square.svg");
      background-size: contain;
      background-repeat: no-repeat;

      > div {
        height: 100%;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }

    @include media-breakpoint-down(md) {
      text-align: center;

      .feature-image {
        margin: auto auto 1rem;
      }
    }

    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;

      .feature-image {
        margin-right: 2rem;
      }

      .feature-text > * {
        text-align: left;
      }
    }
  }
}
