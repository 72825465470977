$video-background: #171717;
$video-box-shadow: 0px 32px 48px $shadow-light;
$video-selector-margin-top: 0.5rem;
$video-selector-margin-y: 0.1rem;
$video-selector-margin-x: 0.5rem;

#technical {
  position: relative;
  color: $white;
  @include padding-y($block-padding);

  h1 {
    color: $theme;
  }

  > div {
    position: relative;
  }

  #technical-background {
    @include absolute-full-cover();

    @include media-breakpoint-up(md) {
      background-image: url("../../img/logo/outline-theme.svg");
      background-position-y: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-origin: content-box;
    }
  }

  #technical-video-container {
    width: 100%;
    margin: auto;

    @include media-breakpoint-up(md) {
      width: 65vw;

    }
  }

  .technical-video-selectors {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: $video-selector-margin-top #{-$video-selector-margin-y} #{-$video-selector-margin-x};

    .technical-video-selector {
      flex: 0 1 10rem;
      margin: $video-selector-margin-y $video-selector-margin-x;
      white-space: nowrap;
    }
  }

  .technical-videos {
    aspect-ratio: 16/9;
    box-shadow: $video-box-shadow;
    border-radius: $border-radius;
    overflow: hidden;
    position: relative;

    .technical-video {
      position: absolute;
      display: flex;

      video {
        display: none;
        width: 100%;
        height: 100%;

        &.active {
          display: block;
        }
      }

      .not-supported {
        display: flex;
        width: 100%;
        height: 100%;
        background-color: $video-background;

        > div {
          margin: auto;
          padding: $block-padding;
        }
      }
    }
  }
}
