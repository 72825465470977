$z-index-base: 100;
$max-text-width: 30rem;
$max-element-width: $max-text-width * 1.5;

#settopbox {
  @include padding-y($block-padding);
  z-index: $z-index-base;

  #settopbox-background {
    display: none;
  }

  #settopbox-image {
    .rect-backdrop > div {
      background-color: $theme;
    }

    .image-container {
      z-index: $z-index-base + 2;
      background-image: url("../../img/settopbox.png");
    }
  }

  #settopbox-text {
    button {
      margin-top: 1rem;
    }
  }

  @include media-breakpoint-down(md) {
    #settopbox-1 {
      position: relative;

      #settopbox-background {
        display: block;
        z-index: $z-index-base + 1;
        @include absolute-full-cover();

        > div {
          height: 100%;
          width: 100%;
          @include margin-x(auto);
          max-width: $max-element-width * 2;

          background-image: url("../../img/logo/indented-half.svg");
          background-position: center;
          background-repeat: no-repeat;
          background-size: 80%;
          background-origin: content-box;
        }
      }

      #settopbox-image {
        position: relative;

        .image-container {
          @include absolute-full-cover();
          background-position: right;
          background-repeat: no-repeat;
          background-size: contain;
          background-origin: content-box;
        }
      }

      .rect-container {
        height: 60vw;
        max-height: 25rem;
      }
    }

    #settopbox-text {
      text-align: center;
    }
  }

  @include media-breakpoint-up(md) {
    #settopbox-container {
      display: flex;
      justify-content: center;
      position: relative;

      #settopbox-background {
        display: block;
        z-index: $z-index-base + 1;
        @include absolute-full-cover();

        > div {
          height: 100%;
          width: 100%;
          @include margin-x(auto);
          max-width: $max-element-width * 2;

          background-image: url("../../img/logo/indented.svg");
          background-position: center;
          background-repeat: no-repeat;
          background-size: 80%;
          background-origin: content-box;
        }
      }

      #settopbox-1, #settopbox-2 {
        flex: 1 1 $max-element-width;
        max-width: $max-element-width;
        @include padding-x(1rem);
      }

      #settopbox-1 {
        #settopbox-image-container {
          height: 100%;

          #settopbox-image {
            height: 100%;
            position: relative;

            .rect-element {
              height: 100%;
            }

            .image-container {
              @include absolute-full-cover();
              background-position: right;
              background-repeat: no-repeat;
              background-size: contain;
              background-origin: content-box;
            }
          }
        }

        .rect-element {
          @include padding-y(0);
        }
      }

      #settopbox-2 {
        z-index: $z-index-base + 2;

        #settopbox-text {
          max-width: $max-text-width;

          > * {
            text-align: left;
          }
        }
      }
    }
  }
}
