@import "variables";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/utilities/api";
@import "~overlayscrollbars/css/OverlayScrollbars.css";

@import "mixins/position";

html {
  // Prevent overflow for real small screens
  overflow-x: hidden;
}

#content > * {
  position: relative;
  @include padding-x($page-padding);
}

@import "layout/button";
@import "layout/text";

@import "elements/rect";
@import "elements/welcome";
@import "elements/usp";
@import "elements/packages";
@import "elements/privacy";
@import "elements/settopbox";
@import "elements/technical";
@import "elements/apps";
@import "elements/features";
@import "elements/faq";

@font-face {
  font-family: "Celdum-Regular";
  src: url('../fonts/CeldumRegular/font.woff2') format('woff2'), url('../fonts/CeldumRegular/font.woff') format('woff');
}
@font-face {
  font-family: "Celdum-Bold";
  src: url('../fonts/CeldumBold/font.woff2') format('woff2'), url('../fonts/CeldumBold/font.woff') format('woff');
}
@font-face {
  font-family: "Norpeth-DemiBold";
  src: url('../fonts/NorpethDemiBold/font.woff2') format('woff2'), url('../fonts/NorpethDemiBold/font.woff') format('woff');
}
@font-face {
  font-family: "Norpeth-Medium";
  src: url('../fonts/NorpethMedium/font.woff2') format('woff2'), url('../fonts/NorpethMedium/font.woff') format('woff');
}
