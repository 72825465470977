@mixin absolute-full-cover($position: absolute) {
  position: $position;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@mixin padding-y($padding) {
  padding-top: $padding;
  padding-bottom: $padding;
}

@mixin padding-x($padding) {
  padding-left: $padding;
  padding-right: $padding;
}

@mixin margin-y($padding) {
  margin-top: $padding;
  margin-bottom: $padding;
}

@mixin margin-x($padding) {
  margin-left: $padding;
  margin-right: $padding;
}
