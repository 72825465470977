$video-background: #171717;
$video-box-shadow: 0px 32px 48px $shadow-dark;
$background-start: 10rem;

#welcome {
  position: relative;
  color: $white;
  text-align: center;

  #welcome-background-1, #welcome-background-2 {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -$background-start * 1.5;
  }

  #welcome-background-1 {
    top: 0;
    background: $theme-dark;
    background: linear-gradient(45deg, $theme-dark 0%, rgb(8, 1, 12) 100%);
  }

  #welcome-background-2 {
    top: $background-start;

    @include media-breakpoint-up(md) {
      background-image: linear-gradient(transparent, rgb(8, 1, 12) 90%), url("../../img/logo/outline-theme.svg");
      background-repeat: repeat-y;
      background-size: contain;
      background-origin: content-box;
    }
  }

  > * {
    position: relative;
  }

  #welcome-header {
    min-height: $header-padding * 2 + $header-height;
    padding: $header-padding;

    background-image: url("../../img/logo/normal.svg");
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    background-origin: content-box;

    &.large {
      min-height: 8rem;
    }
  }

  #welcome-leader {
    display: flex;
    width: 100%;
    aspect-ratio: 16/9;
    margin: auto;
    background-color: $video-background;
    box-shadow: $video-box-shadow;
    border-radius: $border-radius;

    > div {
      margin: auto;
      padding: $block-padding;
    }

    @include media-breakpoint-up(md) {
      width: 65vw;
    }
  }

  #welcome-text, #welcome-mission {
    @include padding-y($block-padding);
  }

  #welcome-app {
    padding-bottom: $block-padding;
  }
}
