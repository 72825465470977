#usp-list {
  display: flex;
  justify-content: center;

  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
  }

  .usp {
    padding: $rect-padding;
    flex: 1 0 ($rect-padding * 2);
    max-width: $homepage-element-max-width;

    .usp-image {
      width: 8rem;
      aspect-ratio: 1;
      margin: auto;

      background-image: url("../../img/square.svg");
      background-size: contain;
      background-repeat: no-repeat;

      > div {
        height: 100%;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }

    .usp-title {
      text-transform: uppercase;
      margin-top: 1rem;
    }
  }
}
