.rect-element {
  padding: $rect-padding;
  flex: 1 0 ($rect-padding * 2);
  color: $black;

  > .rect-container {
    position: relative;
    min-width: $rect-padding * 3;
    height: 100%;
    min-height: $rect-padding * 6;

    > .rect-backdrop {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      display: flex;
      flex-direction: column;

      filter: drop-shadow($rect-drop-shadow);

      > .rect-top, .rect-fill, .rect-bottom {
        background: $rect-background;
      }

      > .rect-top, .rect-bottom {
        flex: 0 0 auto;
      }

      .rect-top {
        aspect-ratio: 1/0.15;
        mask: url('../../rect/top.svg') top/100% auto no-repeat;
      }

      > .rect-fill {
        flex: 1 1 auto;
        margin: -1px 0;
      }

      .rect-bottom {
        aspect-ratio: 1/0.15;
        mask: url('../../rect/bottom.svg') bottom/100% auto no-repeat;
      }
    }

    > .rect-content {
      display: flex;
      flex-direction: column;
      position: relative;
      padding: $rect-padding * 0.75 $rect-padding;
      background: transparent;
    }
  }

  &.package {
    max-width: $homepage-element-max-width;
  }
}
