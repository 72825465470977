$package-separator-margin: 0.75rem;
$package-separator-width: 60%;
$package-separator-line-width: 0.2rem;
$package-channel-spacer: 1rem;

#packages {
  color: $white;

  #package-list {
    display: flex;
    justify-content: center;

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
    }

    .rect-element {
      .rect-content {
        height: 100%;
      }

      .package-header {
        text-transform: uppercase;

        .package-brand {
          font-family: $celdum;
          color: $theme;
          margin-bottom: -0.5rem;
        }

        .package-name {
          font-family: $celdum-bold;
          font-size: larger;
          font-weight: bold;
        }
      }

      .package-separator {
        margin: $package-separator-margin 0;
        width: $package-separator-width;
        border-top: $package-separator-line-width solid $theme;
      }

      .package-channel-list {
        font-family: $norpeth-bold;
        flex: 1 0 auto;
        list-style: none;
        margin: 0 0 $package-channel-spacer;
        padding: 0;

        li {
          font-size: small;
          color: $gray-700;
        }
      }
    }
  }
}
